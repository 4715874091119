document.addEventListener("DOMContentLoaded", function() {
  const experienceCards = document.querySelectorAll('.experience__card');

  experienceCards.forEach(card => {
    card.addEventListener('click', function() {
      const pageId = this.id; // Get the ID of the clicked element
      const pageURL = `${pageId}.html`; // Construct the URL

      // Open the new page
      window.location.href = pageURL;
    });
  });
});